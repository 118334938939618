import render102 from '../../images/render102.webp'
import render90 from '../../images/render90.webp'
import render83 from '../../images/render83.webp'
import render38 from '../../images/render38.webp'
import render75 from '../../images/render75.webp'
import render71 from '../../images/render71.webp'
import render712 from '../../images/render712.webp'
import render34 from '../../images/render34.webp'
import render57 from '../../images/render57.webp'
import render62 from '../../images/render62.webp'

export const ApartmentsArr =[
    {
        id: 1,
        image: `${render102}`,
        text1: '/ 102.8 M2',
        text2: '/ 2',
        link: '/components/Apartments/apartmentsInner/Apartment102'
    },

    {
        id: 2,
        image: `${render90}`,
        text1: '/ 90 M2',
        text2: '/ 2',
        link: '/components/Apartments/apartmentsInner/Apartment90'
    },

    {
        id: 3,
        image: `${render83}`,
        text1: '/ 83.4 M2',
        text2: '/ 2',
        link: '/components/Apartments/apartmentsInner/Apartment83'
    },

    {
        id: 5,
        image: `${render75}`,
        text1: '/ 75 M2',
        text2: '/ 2',
        link: '/components/Apartments/apartmentsInner/Apartment75'
    },

    {
        id: 6,
        image: `${render71}`,
        text1: '/ 71.5 M2',
        text2: '/ 2',
        link: '/components/Apartments/apartmentsInner/Apartment71Sec',
        class: 'position71sec'
    },

    {
        id: 7,
        image: `${render712}`,
        text1: '/ 71.5 M2',
        text2: '/ 2',
        link: '/components/Apartments/apartmentsInner/Apartment71'
    },

    {
        id: 12,
        image: `${render62}`,
        text1: '/ 62.3 M2',
        text2: '/ 2',
        link: '/components/Apartments/apartmentsInner/Apartment62'
    },

    {
        id: 9,
        image: `${render57}`,
        text1: '/ 57.6 M2',
        text2: '/ 1',
        link: '/components/Apartments/apartmentsInner/Apartment57'
    },

    {
        id: 10,
        image: `${render38}`,
        text1: '/ 38.9 M2',
        text2: '/ 1',
        link: '/components/Apartments/apartmentsInner/Apartment38'
    },

    {
        id: 11,
        image: `${render34}`,
        text1: '/ 34.5 M2',
        text2: '/ 1',
        link: '/components/Apartments/apartmentsInner/Apartment34'
    },
]
